@import 'src/styles/mixins';

.row {
  align-items: center;
  display: flex;
  margin-bottom: $gapXL;
}

.rowLabel {
  margin-right: $gapL;
  min-width: 22rem;
}
