@import 'src/styles/mixins';

$fileUploadBgColor: #fafbfd;
$fileUploadWidth: $inputMaxWidth;

$imageWrapperSize: 6rem;
$imageMaxSize: 4rem;

.wrapper {
  display: flex;
}

.label {
  @include font-size-s;
  box-sizing: border-box;
  color: $textColor;
  display: flex;
  flex-direction: column;
  margin-bottom: $gapM;
  margin-right: $gapXL;
  width: 21rem;
}

.fileFormats {
  color: $textColorLight;
  margin-top: 0.2rem;
}

.fileUploadWrapper {
  @include input-bottom-margin;
  display: flex;
  flex-direction: column;
}

.fileUpload {
  @include default-transition;
  @include font-size-s;
  align-items: center;
  background: $fileUploadBgColor;
  border: 2px dashed $lineColor;
  border-radius: $borderRadius;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: $basicElementHeight;
  justify-content: center;
  position: relative;
  width: $fileUploadWidth;

  &_disabled {
    color: $textColorLight;
  }

  &_error {
    border-color: $errorColor;
  }
}

.input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;

  &_disabled {
    cursor: default;
  }
}

.selectFile {
  color: $primaryColor;

  &_disabled {
    color: $textColorLight;
  }
}

.imageWrapper {
  @include size($imageWrapperSize);
  align-items: center;
  background: $inputBgColor;
  border-radius: $borderRadius;
  display: flex;
  justify-content: center;
  margin-right: $gapXL;
}

.image {
  max-height: $imageMaxSize;
  max-width: $imageMaxSize;
}

.row {
  align-items: center;
  display: flex;
  margin-bottom: $gapL;
  margin-top: $gapXL;
}

.removeFile {
  border: 0;
  margin-left: $gapXL;
}

.error {
  @include font-size-s;
  color: $errorColor;
  margin-top: $gapL;
  width: $fileUploadWidth;
}
